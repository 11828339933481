@import url("https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@400&family=Stint+Ultra+Condensed&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;600&display=swap');

.App {
  font-family: "Saira Extra Condensed", sans-serif;
  text-align: center;
  padding: 0;
  margin: 0;
}

body{
  padding: 0;
  margin: 0;
}
.container {
  min-width: 750px;
  margin: auto;
  padding: 0;
}

g.yAxis line {
  stroke: grey;
  stroke-dasharray: 1 1;
}

g.yAxis path {
  display: none;
}

g.yAxis {
  font-family: "Saira Extra Condensed", sans-serif;
  font-size: 0.9em;
}


g.xAxis {
  font-family: "Saira Extra Condensed", sans-serif;
  font-size: 0.9em;
  color: navy;

}

.bands{
  fill: #c2ecb4;
  stroke: #c2ecb4;
  opacity: 0.7;
}
.colcircle{
  fill: orange;
  stroke: orange;
}

.tooltip{
  position: absolute;
  padding: 10px;
  font: 10px sans-serif;
  background: #444;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  color: white;
}

.tooltip p{
  padding: 0;
  margin: 0;
  gap:1;
}

.strong{
  color: orange;
  font-weight: 500;
}

#selOpt{
  font-family: sans-serif;
  min-width: 150px;
}

/*----------------------------------------------------------------
Header Classes
----------------------------------------------------------------*/
.header{
  margin: 0;
  padding: 0;
  min-height: 7vh;
  width: 100vw;
  background-color: #555;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
}

.logocontainer{
  background-color: none;
  font-size: 2rem;
  color: #5fe3b3;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,"Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif, 'Inter', sans-serif;
  padding-top: 10px;
  font-weight: 600;
}

.nav{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style: none;
  font-family: proxima-nova, 'Inter', sans-serif;
  color: white;
  font-size: 1.2rem;
}

.nav li{
  display: block;
}
.nav li:hover{
  background-color: #5fe3b3;
}

.selectionRow{
  padding-top: 20px;
  width: 100%;
  max-width: 900px;
  display: grid;
  grid-template-columns: 200px 1fr 2fr;
  margin: auto;
}

.selectionBox{
  align-self: start;
  border: none;
  border-radius: 5px;
  background-color: #c2ecb4;
  text-align: left;
  padding: 10px;
}